import * as React from 'react';
import { AppProps, SeoObject } from 'src/app/types';
import { AppPageLayout, AppPageLinks } from 'src/app.consumer/components';
import { Localized } from 'src/app/components';
import { SendPasswordEmailForm } from 'src/app/container';

const seo: SeoObject = {
  title: 'Lost password',
};

const LostPasswordPage = (props: AppProps) => {
  return (
    <AppPageLayout
      sidebar={<AppPageLinks />}
      subTitle={<Localized dictKey={'staticLocales:pages./lost-password.subtitle'} />}
      title={<Localized dictKey={'staticLocales:pages./lost-password.link label'} />}
      {...{ ...props, seo }}
    >
      <div className='py-2'>
        <SendPasswordEmailForm />
      </div>
    </AppPageLayout>
  );
};

export default LostPasswordPage;
